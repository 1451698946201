import React, { useState, useEffect } from 'react'
import { makeRequest } from '../../helpers'
import InsuranceBasicView from './insurance-basics-view'

const InsuranceBasicController = () => {
  const [loading, setLoading] = useState(true)
  const [insurance, setInsurance] = useState([])

  const getInBasics = async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'page',
      params: {
        slug: 'insurance-and-tpa-basics',
      },
    })
      .then(({ data }) => {
        setInsurance(data.content)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    getInBasics()
  }, [])

  const viewProps = {
    loading,
    data: insurance,
  }
  return <InsuranceBasicView {...viewProps} />
}

export default InsuranceBasicController
